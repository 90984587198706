import { useEffect } from 'react';

import { useShallow } from 'zustand/react/shallow';

import { useStudyStackStore } from '../store';

export default function useLongLivedJwt() {
	const { fetchLongLivedJwt, sendLaunchedEvent } = useStudyStackStore(
		useShallow((state) => ({
			fetchLongLivedJwt: state.fetchLongLivedJwt,
			sendLaunchedEvent: state.sendLaunchedEvent
		}))
	);

	useEffect(() => {
		fetchLongLivedJwt().then(() => sendLaunchedEvent());
	}, [fetchLongLivedJwt, sendLaunchedEvent]);
}
