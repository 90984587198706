import axiosDefault, { type AxiosError, type AxiosResponse } from 'axios';
import { produce } from 'immer';
import jwtDecode from 'jwt-decode';
import { last, merge, random, shuffle } from 'lodash-es';
import { createSelector } from 'reselect';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import type { StudentViewElementProps } from '@soomo/lib/components/pageElements/MultipleChoiceQuestionPool/types';
import { getCurrentPoolItem } from '@soomo/lib/components/pageElements/MultipleChoiceQuestionPool/utils';
import type { MCQuestionAnswer } from '@soomo/lib/types';
import type { FamilyId, MCQuestionPoolElement } from '@soomo/lib/types/WebtextManifest';

const STUDY_STACK_MCQPS_ENDPOINT = `https://${import.meta.env.VITE_SOOMO_CORE_API_HOST}/api/courses/v1/study_stack/mc_question_pools`;
const JOB_RESULTS_ENDPOINT = (jobId: string) =>
	`https://${import.meta.env.VITE_SOOMO_CORE_API_HOST}/jobs/${jobId}/results`;
const EVENTS_ENDPOINT = `https://${import.meta.env.VITE_SOOMO_CORE_API_HOST}/api/courses/v1/events`;

export const ALL_CHAPTERS = 'ALL_CHAPTERS' as const;

interface Page {
	family_id: FamilyId;
	chapter_family_id: FamilyId;
	name: string;
	/** Relative URL to the page on Core, e.g. "/courses/123/traditional_book/chapters/456/pages/789" */
	page_link: string;
	page_number: string;
}

interface Chapter {
	family_id: FamilyId;
	chapter_number: string;
	name: string;
	pages: Page[];
	total_question_pools: number;
	available_question_pools: number;
}

/** Event object format expected by the "response saved" Keen event. */
interface KeenSaveResponseEventObject {
	type: 'mc_question_pool';
	id: FamilyId;
	session_attempts_used: number;
	session_score: number;
	current_question: {
		type: 'multiple_choice';
		id: FamilyId;
		session_attempts_used: number;
		session_score: number;
	};
}

/** Event object format expected by the "try again" Keen event. */
interface KeenTryAgainEventObject {
	type: 'mc_question_pool';
	id: FamilyId;
	session_attempts_used: number;
	session_score: number;
	current_question: {
		type: 'multiple_choice';
		id: FamilyId;
		session_times_seen: number;
		session_attempts_used: number;
		session_score: number;
	};
}

interface MiniQuizResponse {
	questionPoolFamilyId: FamilyId;
	resetCount: number;
	answer: MCQuestionAnswer | null;
	/** Used to shuffle MC question choices, not study stack items. */
	seed: number;
}

type LaunchSource = 'page' | 'question' | 'tools_menu';

export interface OneTimeUseJwt {
	sub: number;
	cid: number;
	extra: {
		session_uuid: string;
		/**
		 * If Study Stack was launched from Core, this is the URL in Core it was launched from.
		 * We use this for the "Back to webtext" link.
		 * Otherwise null (e.g. when opening Study Stack within the mobile app's in-app browser).
		 */
		launch_url: string | null;
		/**
		 * How Study Stack was launched: from the Tools menu of the webtext,
		 * from a link on the page (e.g. a link at the bottom of the webtext page),
		 * or from a link below a specific MC question pool.
		 */
		launched_from_object: LaunchSource;
		/**
		 * If launched_from_object == 'question', the family ID of the **pool item** (not the pool itself)
		 * that was visible when Study Stack was launched.
		 */
		current_question_family_id: FamilyId | null;
		/**
		 * The family ID of the page that Study Stack was launched from.
		 * Always present, regardless of the value of`launched_from_object`.
		 */
		launched_from_page_family_id: FamilyId;
		num_mcqps_available: number;
	};
}

export interface LongLivedJwt {
	sub: number;
	cid: number;
	extra: {
		userInfo: {
			userId: number;
			intercomHash: string;
			fullstoryEnabled: boolean;
			fullstoryProperties: object;
			email: string;
			firstName: string;
			lastName: string;
		};
	};
}

interface StartJobResponse {
	job_id: string;
}

interface StudyStackMcqpsResponse {
	question_pools: MCQuestionPoolElement[];
	reset_counts: {
		[questionPoolFamilyId: FamilyId]: number;
	};
	chapters: Chapter[];
	config: {
		chapter_names_include_numbering: boolean;
	};
}

type AppLoadingState =
	| 'loading'
	| 'error-token-expired'
	| 'error-no-token'
	| 'error-unknown'
	| 'ready';

interface Actions {
	clearResponses: () => void;
	shuffleQuestionPools: () => void;
	setChapterFilter: (chapterFilter: FamilyId | typeof ALL_CHAPTERS) => void;
	setAppLoadingState: (appLoadingState: AppLoadingState) => void;
	saveQuestionPool: StudentViewElementProps['onSave'];
	resetQuestionPool: StudentViewElementProps['onReset'];
	fetchJobResults: () => Promise<void>;
	fetchLongLivedJwt: () => Promise<void>;
	onActionCableConnected: () => Promise<void>;
	onJobComplete: (completedJobId: string) => void;
	sendLaunchedEvent: () => Promise<AxiosResponse<void>>;
}

interface State {
	jwt: string | null;
	appLoadingState: AppLoadingState;
	responses: {
		/**
		 * Keyed by MC question pool family ID. The value is an array of responses for that question pool,
		 * with the most recent response at the end of the array (`arr.at(-1)`).
		 */
		[assigmentFamilyId: FamilyId]: MiniQuizResponse[];
	};
	questionPools: MCQuestionPoolElement[];
	chapters: Chapter[];
	pages: {
		[pageFamilyId: FamilyId]: Page;
	};
	userId: number;
	courseId: number;
	chapterFilter: FamilyId | typeof ALL_CHAPTERS | null;
	backToWebtextUrl: string | null;
	noMcqpsAvailable: boolean;
	sessionUuid: OneTimeUseJwt['extra']['session_uuid'];
	launchedFromObject: OneTimeUseJwt['extra']['launched_from_object'];
	currentQuestionFamilyId: OneTimeUseJwt['extra']['current_question_family_id'];
	launchedFromPageFamilyId: OneTimeUseJwt['extra']['launched_from_page_family_id'];
	config: Partial<StudyStackMcqpsResponse['config']>;
	initialResetCounts: StudyStackMcqpsResponse['reset_counts'];
	jobId: string | null;
}

const getInitialState = (): State => {
	const initialState: State = {
		jwt: null,
		appLoadingState: 'loading',
		responses: {},
		questionPools: [],
		chapters: [],
		pages: {},
		config: {},
		userId: 0,
		courseId: 0,
		chapterFilter: null,
		backToWebtextUrl: null,
		noMcqpsAvailable: false,
		initialResetCounts: {},
		jobId: null,
		sessionUuid: '',
		launchedFromObject: '' as never,
		launchedFromPageFamilyId: '',
		currentQuestionFamilyId: null
	};

	const oneTimeUseJwt = new URL(document.location.href).searchParams.get('jwt');
	if (!oneTimeUseJwt) {
		initialState.appLoadingState = 'error-no-token';
		return initialState;
	}

	const { sub: userId, cid: courseId, extra } = jwtDecode<OneTimeUseJwt>(oneTimeUseJwt);
	const backToWebtextUrl = extra.launch_url;
	const noMcqpsAvailable = extra.num_mcqps_available === 0;

	initialState.userId = userId;
	initialState.courseId = courseId;
	initialState.backToWebtextUrl = backToWebtextUrl;
	initialState.noMcqpsAvailable = noMcqpsAvailable;
	initialState.sessionUuid = extra.session_uuid;
	initialState.launchedFromObject = extra.launched_from_object;
	initialState.launchedFromPageFamilyId = extra.launched_from_page_family_id;
	initialState.currentQuestionFamilyId = extra.current_question_family_id;
	if (noMcqpsAvailable) {
		initialState.appLoadingState = 'ready';
	}

	return initialState;
};

export const useStudyStackStore = create<Actions & State>(
	// @ts-expect-error typechecking is busted on zustand middleware
	persist(
		devtools(
			immer((set, get) => {
				const axiosInstance = axiosDefault.create();
				axiosInstance.interceptors.response.use(undefined, (error: AxiosError) => {
					set({
						appLoadingState:
							error.response?.status === 401 ? 'error-token-expired' : 'error-unknown'
					});
					throw error;
				});

				async function sendKeenEvent(eventType: string, eventProperties?: object) {
					const { sessionUuid, courseId } = get();
					const payload = merge(
						{
							action_name: eventType,
							course_id: courseId,
							study_stack_session_uuid: sessionUuid
						},
						eventProperties
					);
					return await axiosDefault.post<void>(EVENTS_ENDPOINT, payload, {
						headers: {
							Authorization: `Bearer ${get().jwt}`
						}
					});
				}

				/**
				 * 2.4.18 User generates a list of questions in a Study Stack session
				 * This event corresponds to the use of the "Generate" button with the Study Stack tool and includes details
				 * about the scope of the generated "stack."
				 */
				async function sendQuestionsGeneratedEvent(
					chapterFamilyId: FamilyId | typeof ALL_CHAPTERS
				) {
					return await sendKeenEvent('generate', {
						object_name: 'study_stack_session',
						chapter_family_id: chapterFamilyId
					});
				}

				/**
				 * 2.4.19 User shuffles questions in a generated Study Stack session
				 * Event generated when the user employs the "shuffle" feature within their session.
				 */
				async function sendQuestionsShuffledEvent() {
					return await sendKeenEvent('shuffle', {
						object_name: 'study_stack_session'
					});
				}

				/**
				 * 2.4.20 User saves an answer and sees a response in a Study Stack session
				 * This event represents the answering of a question within Study Stack.
				 */
				async function sendAnswerSavedEvent({
					questionPoolFamilyId,
					questionFamilyId
				}: {
					questionPoolFamilyId: FamilyId;
					questionFamilyId: FamilyId;
				}) {
					const poolResponses = get().responses[questionPoolFamilyId];
					const poolItemResponses = poolResponses.filter(
						(r) => r.answer?.question_family_id === questionFamilyId
					);
					return await sendKeenEvent('save_response', {
						object_name: 'study_stack_question',
						object: {
							type: 'mc_question_pool',
							id: questionPoolFamilyId,
							session_attempts_used: poolResponses.length,
							session_score: poolResponses.filter((r) => r.answer?.correct).length,
							current_question: {
								type: 'multiple_choice',
								id: questionFamilyId,
								session_attempts_used: poolItemResponses.length,
								session_score: poolItemResponses.filter((r) => r.answer?.correct).length
							}
						} satisfies KeenSaveResponseEventObject,
						attempt_score: last(poolItemResponses)!.answer!.correct ? 1 : 0
					});
				}

				/**
				 * 2.4.21 User resets a question in a Study Stack session
				 * This event represents the resetting of a single question within a Study Stack session,
				 * using the "Try Again" button.
				 */
				async function sendTryAgainEvent({
					questionPoolFamilyId
				}: {
					questionPoolFamilyId: FamilyId;
				}) {
					const { responses, questionPools, userId } = get();
					const pool = questionPools.find((qp) => qp.family_id === questionPoolFamilyId)!;
					// poolResponses contains all responses for this MCQP, including the reset that just happened...
					const poolResponses = responses[questionPoolFamilyId];
					// ... while poolPriorResponses excludes that reset
					const poolPriorResponses = responses[questionPoolFamilyId].slice(0, -1);
					const newPoolItem = getCurrentPoolItem({
						element: pool,
						numResets: last(poolResponses)!.resetCount,
						userId
					});
					const newPoolItemPriorResponses = poolPriorResponses.filter(
						(r) => r.answer?.question_family_id === newPoolItem.family_id
					);
					return await sendKeenEvent('try_again', {
						object_name: 'study_stack_question',
						object: {
							type: 'mc_question_pool',
							id: questionPoolFamilyId,
							session_attempts_used: poolPriorResponses.length,
							session_score: poolPriorResponses.filter((r) => r.answer?.correct).length,
							current_question: {
								type: 'multiple_choice',
								id: newPoolItem.family_id,
								session_times_seen: newPoolItemPriorResponses.length,
								session_attempts_used: newPoolItemPriorResponses.length,
								session_score: newPoolItemPriorResponses.filter((r) => r.answer?.correct).length
							}
						} satisfies KeenTryAgainEventObject
					});
				}

				return {
					...getInitialState(),
					setChapterFilter: (chapterFilter) => {
						set({ chapterFilter });
						sendQuestionsGeneratedEvent(chapterFilter);
					},
					setAppLoadingState: (appLoadingState) => set({ appLoadingState }),
					clearResponses: () =>
						set((state) => {
							state.responses = createInitialResponses(
								state.questionPools,
								state.initialResetCounts
							);
						}),
					fetchLongLivedJwt: async () => {
						const { jwt, courseId } = get();
						if (jwt) {
							return;
						}

						const oneTimeUseJwt = new URL(document.location.href).searchParams.get('jwt');
						if (!oneTimeUseJwt) {
							return set({ appLoadingState: 'error-no-token' });
						}

						const { data: longLivedJwt } = await axiosInstance.get<string>(
							`https://${import.meta.env.VITE_SOOMO_CORE_API_HOST}/api/auth/v1/token?course_id=${courseId}`,
							{
								headers: {
									Accept: 'text/plain',
									Authorization: `Bearer ${oneTimeUseJwt}`
								}
							}
						);
						set({ jwt: longLivedJwt }); // persist long lived JWT in sessionStorage for future requests
					},
					onActionCableConnected: async () => {
						const { courseId, noMcqpsAvailable } = get();
						// if Core is telling us we'll have no MCQPs available,
						// then don't bother fetching anything
						if (noMcqpsAvailable) {
							return;
						}

						// otherwise, request background job
						const fetchUrl = new URL(STUDY_STACK_MCQPS_ENDPOINT);
						fetchUrl.searchParams.set('course_id', `${courseId}`);
						const response = await axiosInstance.get<StartJobResponse>(fetchUrl.toString(), {
							headers: {
								Accept: 'application/json',
								Authorization: `Bearer ${get().jwt}`
							}
						});
						const { job_id } = response.data;
						set({ jobId: job_id });
					},
					onJobComplete: (completedJobId: string) => {
						const { jobId, fetchJobResults } = get();
						if (jobId === completedJobId) {
							fetchJobResults();
						}
					},
					fetchJobResults: async () => {
						const { jobId, jwt } = get();
						if (!jwt || !jobId) {
							throw new Error("Can't fetch job results without a jwt and a jobId");
						}

						const fetchUrl = JOB_RESULTS_ENDPOINT(jobId);
						const jobResultsResponse = await axiosInstance.get<string>(fetchUrl, {
							headers: {
								Accept: 'text/plain',
								Authorization: `Bearer ${jwt}`
							}
						});
						const s3Url = jobResultsResponse.data;
						const s3Response = await axiosInstance.get<StudyStackMcqpsResponse>(s3Url);
						const {
							chapters: rawChapters,
							config,
							question_pools: rawQuestionPools,
							reset_counts: initialResetCounts
						} = s3Response.data;

						const domParser = new DOMParser();
						const chapters = rawChapters.map((ch) => ({
							...ch,
							name: domParser.parseFromString(ch.name, 'text/html').body.textContent!
						}));
						const pages = Object.fromEntries(
							rawChapters.flatMap((ch) => ch.pages).map((p) => [p.family_id, p])
						);
						const questionPools = rawQuestionPools.map((mcqp) => {
							const page = pages[mcqp.page_id];
							const mcqpWithRejoinderLink = addPageLinkToMCQPRejoinders(mcqp, page);
							return mcqpWithRejoinderLink;
						});

						set({
							config,
							chapters,
							pages,
							questionPools,
							initialResetCounts,
							responses: createInitialResponses(questionPools, initialResetCounts),
							appLoadingState: 'ready'
						});
					},
					saveQuestionPool: ({ questionPoolFamilyId, questionFamilyId, choiceFamilyId }) => {
						const questionPool = get().questionPools.find(
							(mcqp) => mcqp.family_id === questionPoolFamilyId
						)!;
						const poolItem = questionPool.questions.find(
							(mcq) => mcq.family_id === questionFamilyId
						)!;
						const choice = poolItem.choices.find((c) => c.family_id === choiceFamilyId)!;
						const answer = {
							body: choiceFamilyId,
							question_family_id: questionFamilyId,
							correct: choice.is_correct,
							completed: true,
							rejoinder: choice.rejoinder
						} as unknown as MCQuestionAnswer; // deliberately omitting the other props
						set((state) => {
							last(state.responses[questionPoolFamilyId])!.answer = answer;
						});
						sendAnswerSavedEvent({
							questionPoolFamilyId,
							questionFamilyId
						});
						return {
							answer
						};
					},
					resetQuestionPool: ({ questionPoolFamilyId }) => {
						set((state) => {
							const lastResponse = last(state.responses[questionPoolFamilyId]);
							state.responses[questionPoolFamilyId].push({
								questionPoolFamilyId,
								answer: null,
								resetCount: lastResponse!.resetCount + 1,
								seed: generateSeed()
							});
						});
						sendTryAgainEvent({
							questionPoolFamilyId
						});
					},
					shuffleQuestionPools: () => {
						set({
							questionPools: shuffle(get().questionPools)
						});
						sendQuestionsShuffledEvent();
					},
					/**
					 * 2.4.17 A Study Stack session is launched
					 */
					sendLaunchedEvent: async () => {
						const { launchedFromObject, launchedFromPageFamilyId, currentQuestionFamilyId } = get();
						return await sendKeenEvent('launch', {
							object_name: 'study_stack_session',
							launched_from_object: launchedFromObject,
							page_family_id: launchedFromPageFamilyId,
							current_question_family_id: currentQuestionFamilyId
						});
					}
				};
			}),
			{ name: 'Study Stack' }
		),
		{
			name: 'study-stack',
			storage: createJSONStorage(() => sessionStorage),
			partialize: (state) => ({
				jwt: state.jwt
			})
		}
	)
);

export const createAppSelector = createSelector.withTypes<State>();

const generateSeed = () => random(0, 10000000000);

/**
 * Appends a link to the page the question pool appears on in Core to each choice's rejoinder, e.g.
 * "(original rejoinder) For more on this topic, see <a href="(Core page link)" target="_blank">page 1</a>."
 */
const addPageLinkToMCQPRejoinders = (
	mcQuestionPool: MCQuestionPoolElement,
	page: Page
): MCQuestionPoolElement => {
	const href = `https://${import.meta.env.VITE_SOOMO_CORE_API_HOST}${page.page_link}`;
	return produce(mcQuestionPool, (mcqp) => {
		mcqp.questions.forEach((q) => {
			q.choices.forEach((ch) => {
				ch.rejoinder = `${ch.rejoinder} For more on this topic, see <a href="${href}" target="_blank">page ${page.page_number.trim() ? page.page_number : page.name}</a>.`;
			});
		});
	});
};

const createInitialResponses = (
	questionPools: MCQuestionPoolElement[],
	initialResetCounts: StudyStackMcqpsResponse['reset_counts']
) => {
	return Object.fromEntries(
		questionPools.map((qp) => [
			qp.family_id,
			[
				{
					questionPoolFamilyId: qp.family_id,
					/**
					 * We add 1 here to the reset count from Core, because if an MCQP is visible in the study tool
					 * it means it has already been answered in Core. So if we want to present them a new item in the same
					 * question pool, we need to increment the existing reset count so that they see the next item in the
					 * sequence in Study Stack.
					 */
					resetCount: initialResetCounts[qp.family_id] + 1,
					answer: null,
					seed: generateSeed()
				}
			]
		])
	);
};
