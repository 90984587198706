import { css } from '@emotion/core';

import { breakpoints, getThemeItem, type Theme } from '@soomo/lib/styles/themes';

export const controlsSection = (theme: Theme) => {
	const {
		fonts: { app: appFont }
	} = theme;

	return css`
		padding: 0;
		font-family: ${getThemeItem(appFont, theme)};
		font-size: 18px;
	`;
};

export const controls = css`
	display: flex;
	margin: 22px 0 30px;
	white-space: nowrap;
	align-items: center;
	column-gap: 46px;

	@media (max-width: ${breakpoints.small}) {
		flex-direction: column;
		row-gap: 11px;
		align-items: flex-end;
		white-space: normal;
	}
`;

export const chapterFilterLabel = css`
	display: inline-flex;
	padding-right: 23px;
	border-right: 1px solid #979797;
	column-gap: 13px;
	align-items: center;
	flex-grow: 1;
`;

export const chapterFilterSelectWrapper = (theme: Theme) => css`
	position: relative;
	flex-grow: 1;
	border-radius: 2px;
	border: 1px solid ${theme.colors.black};
	line-height: 1;

	@media (max-width: ${breakpoints.small}) {
		width: 100%;
	}

	&::after {
		content: '';
		position: absolute;
		width: 15px;
		height: 15px;
		border-style: solid;
		border-color: ${theme.colors.black};
		border-width: 0 2px 2px 0;
		border-radius: 1px;
		transform: rotate(45deg);
		transform-origin: right;
		right: 20px;
		top: calc(50% - 8px);
		pointer-events: none;
	}
`;

export const chapterFilterSelect = css`
	appearance: none;
	width: 100%;
	padding: 12px 48px 12px 15px;
	border: none;
	overflow: hidden !important; // to override Chrome user agent styles
	white-space: nowrap;
	text-overflow: ellipsis;
`;

export const generateButton = css`
	position: relative;

	// the following two rulesets are to prevent layout shift when transitioning between disabled -> enabled
	// and unfocused -> focused
	&:not(:disabled):not(:focus) {
		border-left-width: 2px;
		border-right-width: 2px;
	}

	&:not(:disabled):focus {
		margin: 0 1px;
	}

	@media (max-width: ${breakpoints.small}) {
		width: auto;
		margin-bottom: 0;
	}

	&::before {
		content: '';
		position: absolute;
		left: -25px;
		top: 0;
		bottom: 0;
		border-left: 1px solid #979797;

		@media (max-width: ${breakpoints.small}) {
			content: none;
		}
	}
`;

export const noChapterSelected = css`
	font-style: italic;
`;
