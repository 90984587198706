import { useCallback, useEffect } from 'react';

/**
 * Prevent students from copying question text by preventing the `copy` and `contextmenu` events,
 * unless they specifically have permission to do so.
 *
 * This hook is much the same as mobile's implementation:
 *     soomo-mobile-app/src/hooks/usePreventQuestionTextCopying.ts
 *
 * Which, in turn, is essentially the same as Core's implementation:
 *     soomo/app/assets/javascripts/webtexts/page.question-nocopy.coffee
 */
export default function usePreventQuestionTextCopying(onCopyPrevented: () => void) {
	const callback = useCallback(
		(e: Event) => {
			const selection = window.getSelection();
			for (let i = 0; i < (selection?.rangeCount || 0); i++) {
				let range = null;
				try {
					range = selection!.getRangeAt(i);
				} catch (_) {
					return;
				}
				if (range == null || range.collapsed) return;
				let showWarning = false;
				const docFragment = range.cloneContents();
				if (
					docFragment.querySelector('.question-body') ||
					containedWithinQuestionBodyElement(range.startContainer as HTMLElement)
				) {
					showWarning = true;
				}
				if (showWarning) {
					e.preventDefault();
					onCopyPrevented();
					return;
				}
			}
		},
		[onCopyPrevented]
	);

	useEffect(() => {
		document.addEventListener('copy', callback);
		document.addEventListener('contextmenu', callback);
		return () => {
			document.removeEventListener('copy', callback);
			document.removeEventListener('contextmenu', callback);
		};
	}, [callback]);
}

// soomo/app/assets/javascripts/webtexts/page.question-nocopy.coffee#containedWithinQuestionBodyElement
const containedWithinQuestionBodyElement = (node: HTMLElement | null, depth = 3): boolean => {
	if (!node || !depth) return false;
	if (node.parentElement?.classList.contains('question-body')) return true;
	return containedWithinQuestionBodyElement(node.parentElement, depth - 1);
};
