import React from 'react';

import * as baseStyles from './styles';

const ErrorTokenExpired: React.VFC = () => {
	return (
		<div css={baseStyles.errorPage}>
			<h1>Your Study Stack session has expired.</h1>
			<p>
				To regain access, please close this browser window or tab and then relaunch Study Stack from
				the Tools Menu of your webtext.
			</p>
		</div>
	);
};

export default ErrorTokenExpired;
