import { useEffect, useMemo } from 'react';

import { FullStory, init, isInitialized } from '@fullstory/browser';
import jwtDecode from 'jwt-decode';

import { LongLivedJwt, useStudyStackStore } from '../store';

const { VITE_FULLSTORY_ORG_ID } = import.meta.env;

export default function useFullStory() {
	const jwt = useStudyStackStore((state) => state.jwt);

	const userInfo = useMemo(() => (jwt ? jwtDecode<LongLivedJwt>(jwt).extra.userInfo : null), [jwt]);

	useEffect(() => {
		if (
			!jwt ||
			!userInfo ||
			!VITE_FULLSTORY_ORG_ID ||
			!userInfo.fullstoryEnabled ||
			isInitialized()
		) {
			return;
		}

		init({ orgId: VITE_FULLSTORY_ORG_ID });
		FullStory('setIdentity', {
			uid: `${userInfo.userId}`,
			properties: userInfo.fullstoryProperties
		});
	}, [jwt, userInfo]);
}
