import { useEffect, useMemo, useRef } from 'react';

import jwtDecode from 'jwt-decode';

import { useStudyStackStore, type LongLivedJwt, type OneTimeUseJwt } from '../store';

const { VITE_INTERCOM_APP_ID } = import.meta.env;

declare const window: {
	Intercom?: (
		action: 'update' | 'boot',
		args: {
			app_id?: string;
		} & IntercomProperties
	) => void;
} & Window;

interface IntercomProperties {
	user_id?: number;
	user_hash?: string;
	name?: string;
	email?: string;
}

export default function useIntercom() {
	const hasBooted = useRef(false);
	const jwt = useStudyStackStore((state) => state.jwt);
	const intercomProperties: IntercomProperties = useMemo(() => {
		if (jwt) {
			const userInfo = jwtDecode<LongLivedJwt>(jwt).extra.userInfo;
			return {
				name: `${userInfo.firstName} ${userInfo.lastName}`,
				email: userInfo.email,
				user_id: userInfo.userId,
				user_hash: userInfo.intercomHash
			};
		}

		const oneTimeUseJwt = new URL(document.location.href).searchParams.get('jwt');
		if (oneTimeUseJwt) {
			const decoded = jwtDecode<OneTimeUseJwt>(oneTimeUseJwt);
			return {
				user_id: decoded.sub
			};
		}

		return {};
	}, [jwt]);

	useEffect(() => {
		if (!VITE_INTERCOM_APP_ID) {
			return;
		}

		if (!hasBooted.current) {
			window.Intercom?.('boot', {
				app_id: VITE_INTERCOM_APP_ID,
				...intercomProperties
			});
			hasBooted.current = true;
		} else {
			window.Intercom?.('update', intercomProperties);
		}
	}, [intercomProperties]);
}
