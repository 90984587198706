import { useCallback, useEffect } from 'react';

import { useStudyStackStore } from '../store';
import { selectResponsesCount } from '../store/selectors';

export default function useLostResponsesWarning() {
	const numResponses = useStudyStackStore(selectResponsesCount);

	const unloadCallback = useCallback((e: BeforeUnloadEvent) => {
		e.preventDefault();
		/**
		 * Chrome needs return value to be set, even if it's not shown;
		 * see https://stackoverflow.com/questions/45088861/whats-the-point-of-beforeunload-returnvalue-if-the-message-does-not-get-set
		 *
		 * Also, the `return ''` doesn't work on Chrome, but works for other browsers
		 * The `return (e.returnValue = '')` works for all of them
		 */
		return (e.returnValue = '');
	}, []);

	useEffect(() => {
		if (numResponses > 0) {
			window.addEventListener('beforeunload', unloadCallback);
		}
		return () => window.removeEventListener('beforeunload', unloadCallback);
	}, [numResponses, unloadCallback]);
}
