import React, { useMemo } from 'react';
import { MdArrowBack } from 'react-icons/md';

import { css } from '@emotion/core';
import { last } from 'lodash-es';
import { useShallow } from 'zustand/react/shallow';

import { breakpoints, getThemeItem, type Theme } from '@soomo/lib/styles/themes';

import { useStudyStackStore } from './store';
import { selectFilteredQuestionPools } from './store/selectors';

const Header: React.VFC = () => {
	const { backToWebtextUrl, chapterFilter, responses } = useStudyStackStore(
		useShallow((state) => ({
			backToWebtextUrl: state.backToWebtextUrl,
			chapterFilter: state.chapterFilter,
			responses: state.responses
		}))
	);
	const filteredQuestionPools = useStudyStackStore(selectFilteredQuestionPools);

	const completion = useMemo(() => {
		const { answered, correct } = filteredQuestionPools.reduce(
			(acc, curr) => {
				const answer = last(responses[curr.family_id])!.answer;
				if (answer) {
					acc.answered += 1;
					if (answer.correct) {
						acc.correct += 1;
					}
				}
				return acc;
			},
			{
				answered: 0,
				correct: 0
			}
		);

		return {
			answered,
			total: filteredQuestionPools.length,
			correct,
			percentAnswered:
				filteredQuestionPools.length > 0
					? Math.floor((answered / filteredQuestionPools.length) * 100)
					: 0,
			percentCorrect: answered > 0 ? Math.floor((correct / answered) * 100) : 0
		};
	}, [filteredQuestionPools, responses]);

	return (
		<header id="header" css={styles.root} tabIndex={-1}>
			<div css={styles.backLinkContainer}>
				{backToWebtextUrl && (
					<a
						href={backToWebtextUrl}
						css={styles.backToWebtextLink}
						target="_blank"
						rel="noreferrer">
						<span className="back-text">Back to webtext</span>
						<MdArrowBack className="back-icon" aria-label="Back to webtext" size={24} />
					</a>
				)}
			</div>
			<div css={styles.siteHeading}>Study Stack</div>
			<div css={styles.progress}>
				{chapterFilter != null && completion.total > 0 && (
					<>
						{completion.answered} of {completion.total} answered ({completion.percentAnswered}%) |{' '}
						{completion.correct} of {completion.answered} correct ({completion.percentCorrect}
						%)
					</>
				)}
			</div>
		</header>
	);
};

export default Header;

const styles = {
	root: (theme: Theme) => {
		const {
			fonts: { app: appFont }
		} = theme;

		return css`
			position: sticky;
			top: 0;
			z-index: 1;
			height: var(--header-height);
			display: grid;
			grid-template-columns: auto auto 1fr;
			align-items: center;
			background: #ece9e9;
			font-family: ${getThemeItem(appFont, theme)};
			column-gap: 26px;

			@media (max-width: ${breakpoints.small}) {
				grid-template-columns: auto 1fr;
				column-gap: 6px;
			}
		`;
	},
	backLinkContainer: css`
		display: flex;
		min-width: 18px;
		height: 100%;
		align-items: center;

		@media (max-width: ${breakpoints.small}) {
			grid-row: span 2;
		}
	`,
	backToWebtextLink: (theme: Theme) => css`
		display: inline-flex;
		height: 100%;
		padding: 0 26px;
		font-weight: 500;
		font-size: 16px;
		line-height: 1;
		color: ${theme.colors.black};
		text-decoration: none;
		background: #ddd;
		align-items: center;

		@media (max-width: ${breakpoints.small}) {
			padding: 0 14px;
			background: none;
		}

		.back-text {
			@media (max-width: ${breakpoints.small}) {
				display: none;
			}
		}

		.back-icon {
			display: none;

			@media (max-width: ${breakpoints.small}) {
				display: block;
			}
		}
	`,
	siteHeading: css`
		grid-column: 2;
		font-weight: 700;
		font-size: 20px;
		line-height: 1;

		@media (max-width: ${breakpoints.small}) {
			align-self: flex-end;
			font-size: 16px;
		}
	`,
	progress: css`
		padding-right: 24px;
		justify-self: flex-end;
		font-size: 18px;

		@media (max-width: ${breakpoints.small}) {
			grid-column: 2;
			font-size: 12px;
			justify-self: flex-start;
		}
	`
};
