import React, {
	KeyboardEventHandler,
	MouseEventHandler,
	forwardRef,
	useCallback,
	useImperativeHandle,
	useRef
} from 'react';

import { css } from '@emotion/core';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { type Theme, getThemeItem, breakpoints } from '@soomo/lib/styles/themes';

export interface ModalHandle {
	open: () => void;
	close: () => void;
}

interface Props
	extends Omit<React.HTMLAttributes<HTMLDialogElement>, 'aria-label' | 'aria-labelledby'> {
	id: string;
	children: React.ReactNode;
	title: string;
	actions: React.ReactNode;
}

const ModalDialog = forwardRef<ModalHandle, Props>(
	({ id, children, title, actions, ...rest }, ref) => {
		const { role } = rest;
		const dialogRef = useRef<HTMLDialogElement>(null);

		useImperativeHandle(ref, () => ({
			open: () => {
				dialogRef.current?.showModal();
				disableBodyScroll(dialogRef.current!);
			},
			close: () => {
				dialogRef.current?.close();
				enableBodyScroll(dialogRef.current!);
			}
		}));

		const handleKeyDown: KeyboardEventHandler<HTMLDialogElement> = useCallback(
			(e) => {
				if (e.key === 'Escape' && role === 'alertdialog') {
					e.preventDefault();
				}
			},
			[role]
		);

		const handleMouseDown: MouseEventHandler<HTMLDialogElement> = useCallback(
			(e) => {
				if (role === 'alertdialog') {
					return;
				}

				// close when clicking backdrop; see https://stackoverflow.com/a/76201152
				if (dialogRef.current?.open && e.target === dialogRef.current) {
					dialogRef.current?.close();
					enableBodyScroll(dialogRef.current!);
				}
			},
			[role]
		);

		return (
			<dialog
				id={id}
				ref={dialogRef}
				onKeyDown={handleKeyDown}
				onMouseDown={handleMouseDown}
				aria-labelledby={`${id}-title`}
				css={styles.root}
				{...rest}>
				<div css={styles.contentWrapper}>
					<h1 id={`${id}-title`} css={styles.title}>
						{title}
					</h1>
					<div css={styles.content}>{children}</div>
					<div css={styles.actions}>{actions}</div>
				</div>
			</dialog>
		);
	}
);

ModalDialog.displayName = 'ModalDialog';

export default ModalDialog;

const styles = {
	root: (theme: Theme) => css`
		padding: 0;
		width: 650px;
		margin: 10vh auto 0 auto;
		background-color: #ece9e9;
		color: black;
		border: none;
		border-radius: 8px;
		font-family: ${getThemeItem(theme.fonts.app, theme)};
		animation: appear 0.4s cubic-bezier(0.33, 1, 0.68, 1);

		@media (prefers-reduced-motion: reduce) {
			animation: none;
		}

		@keyframes appear {
			from {
				margin-top: 8vh;
				opacity: 0;
			}
			to {
				margin-top: 10vh;
				opacity: 1;
			}
		}

		&::backdrop {
			background-color: rgba(0, 0, 0, 0.75);
		}
	`,
	contentWrapper: css`
		padding: 24px 32px;
	`,
	title: css`
		margin-bottom: 18px;
		font-size: 32px;
		line-height: 34px;
	`,
	content: (theme: Theme) => {
		// @ts-expect-error theme.modal isn't typed, but it does exist (see libs/Modal/styles)
		const { modal } = theme;
		return css`
			display: grid;
			justify-items: flex-start;
			row-gap: 18px;
			font-family: ${modal.fontFamily};
			font-size: ${modal.fontSize};
			line-height: 1.5;
		`;
	},
	actions: css`
		margin-top: 18px;
		display: flex;
		justify-content: flex-end;
		font-size: 18px;

		@media (max-width: ${breakpoints.small}) {
			margin-top: 24px;
			flex-direction: column-reverse;
			align-items: center;
		}
	`
};
