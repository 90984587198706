import { css } from '@emotion/core';

import { containedStyles } from '@soomo/lib/components/WebtextButton';
import { breakpoints, getThemeItem, type Theme } from '@soomo/lib/styles/themes';

export const numQuestionsHeading = (theme: Theme) => {
	const {
		fonts: { app: appFont }
	} = theme;

	return css`
		margin-top: 34px;
		font-family: ${getThemeItem(appFont, theme)};
		font-size: 24px;
		font-weight: 500;
		line-height: 1;

		@media (max-width: ${breakpoints.small}) {
			margin-top: 0;
			font-size: 18px;
			line-height: 24px;
		}
	`;
};

export const questionList = css`
	margin-top: 38px;
	padding: 0;

	@media (max-width: ${breakpoints.small}) {
		margin-top: 26px;
	}
`;

export const pageBottom = css`
	margin-top: 50px;
	text-align: center;
`;

export const scrollToTop = (theme: Theme) => css`
	${containedStyles(theme)}
	display: inline-block;
	text-decoration: none;
	margin-top: 19px;

	@media (max-width: ${breakpoints.small}) {
		width: auto;
		display: inline-block; // need to specify it twice due to libs styles' specificity
		margin-top: 9px;
		margin-bottom: 0;
	}
`;

export const buttonContainer = css`
	margin-top: 14px;
	display: flex;
	column-gap: 12px;

	@media (max-width: ${breakpoints.small}) {
		flex-direction: column;
		row-gap: 12px;
		align-items: flex-start;
	}
`;

// !important is needed to override libs styles, unfortunately
export const outlineButton = (theme: Theme) => css`
	${containedStyles(theme)}
	padding: 8px 16px;
	color: ${theme.colors.primary} !important;
	background: unset !important;
	font-weight: 400 !important;
	font-size: 15px;
	border: 1px solid ${theme.colors.primary} !important;
	border-radius: 2px !important;

	&[disabled],
	&[aria-disabled='true'] {
		padding: 8px 16px;
		border: 1px solid rgb(88, 88, 88) !important;
		border-radius: 2px !important; // need to specify this again to override libs styles
		color: rgb(88, 88, 88) !important;
		cursor: default;
	}

	@media (max-width: ${breakpoints.small}) {
		width: auto;
		margin-bottom: 0;
	}
`;

export const jumpToUnanswered = (theme: Theme) => css`
	${outlineButton(theme)}
	display: inline-flex;
	align-items: center;
	text-decoration: none;
	flex-grow: 0;
`;
