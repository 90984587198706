import { last } from 'lodash-es';
import pluralize from 'pluralize';

import { ALL_CHAPTERS, createAppSelector } from '.';

export const selectFilteredQuestionPools = createAppSelector(
	[
		(state) => state.questionPools,
		(state) => state.chapters,
		(state) => state.pages,
		(state) => state.chapterFilter
	],
	(questionPools, chapters, pages, chapterFilter) => {
		return chapterFilter !== ALL_CHAPTERS
			? questionPools.filter((mcqp) => {
					const page = pages[mcqp.page_id];
					const chapter = chapters.find((ch) => ch.family_id === page.chapter_family_id)!;
					return chapter.family_id === chapterFilter;
				})
			: questionPools;
	}
);

export const selectResponsesCount = createAppSelector(
	[(state) => state.responses],
	(responses) =>
		Object.values(responses)
			.map((arr) => last(arr)!)
			.filter((mqr) => mqr.answer).length
);

const selectChapterSource = createAppSelector(
	[
		(state) => state.chapterFilter,
		(state) => state.chapters,
		(state) => state.config.chapter_names_include_numbering
	],
	(chapterFilter, chapters, chapterNamesIncludeNumbering) => {
		if (chapterFilter == null) {
			return null;
		}

		if (chapterFilter === ALL_CHAPTERS) {
			return 'all chapters';
		}

		const activeChapter = chapters.find((ch) => ch.family_id === chapterFilter)!;
		return `${!chapterNamesIncludeNumbering && activeChapter.chapter_number.trim() ? `Chapter ${activeChapter.chapter_number}: ` : ''}“${activeChapter.name}”`;
	}
);

export const selectStatusText = createAppSelector(
	[selectChapterSource, selectFilteredQuestionPools, (state) => state.chapterFilter],
	(chapterSource, activeQuestionPools, chapterFilter) => {
		if (!chapterFilter) {
			return null;
		}

		return `Showing ${activeQuestionPools.length} ${pluralize('question', activeQuestionPools.length)} from ${chapterSource}`;
	}
);
