import React, { useCallback, useMemo, useRef } from 'react';

import { last } from 'lodash-es';
import { useShallow } from 'zustand/react/shallow';

import { WebtextButton } from '@soomo/lib/components';
import { useAriaLiveAnnouncer } from '@soomo/lib/components/AriaLiveAnnouncer';
import { getMCQuestionPoolId } from '@soomo/lib/components/pageElements/MultipleChoiceQuestionPool/utils';

import * as styles from './styles';
import ChapterSelect from '../ChapterSelect';
import usePreventQuestionTextCopying from '../hooks/usePreventQuestionTextCopying';
import MCQuestionPool from '../MCQuestionPool';
import ModalDialog, { type ModalHandle } from '../ModalDialog';
import { useStudyStackStore } from '../store';
import { selectFilteredQuestionPools, selectStatusText } from '../store/selectors';
import * as baseStyles from '../styles';

/** e.g. "2:18 PM" */
const timeFormatter = new Intl.DateTimeFormat('en-US', {
	dateStyle: undefined,
	timeStyle: 'short'
});

const MCQuestionPoolList: React.FC = () => {
	const { chapterFilter, responses, shuffleQuestionPools } = useStudyStackStore(
		useShallow((state) => ({
			chapterFilter: state.chapterFilter,
			responses: state.responses,
			shuffleQuestionPools: state.shuffleQuestionPools
		}))
	);

	const { makeAssertiveAnnouncement } = useAriaLiveAnnouncer();

	const activeQuestionPools = useStudyStackStore(selectFilteredQuestionPools);
	const statusText = useStudyStackStore(selectStatusText);

	const modalRef = useRef<ModalHandle>(null);
	usePreventQuestionTextCopying(() => modalRef.current?.open());

	const handleShuffle = useCallback(() => {
		shuffleQuestionPools();
		makeAssertiveAnnouncement(`Questions shuffled at ${timeFormatter.format(new Date())}.`);
	}, [makeAssertiveAnnouncement, shuffleQuestionPools]);

	const handleScrollToTop = useCallback(() => {
		// we don't want to call e.preventDefault() because we want focus to shift to `#header`,
		// but we have to scroll to the top of the page manually because `#header` is sticky
		window.scrollTo({ top: 0 });
	}, []);

	const firstUnansweredQuestionAnchor = useMemo(() => {
		const firstUnansweredMcqp = activeQuestionPools.find(
			(mcqp) => last(responses[mcqp.family_id])!.answer == null
		);
		if (!firstUnansweredMcqp) {
			return undefined;
		}
		return `#${getMCQuestionPoolId(firstUnansweredMcqp.family_id)}`;
	}, [activeQuestionPools, responses]);

	return (
		<>
			<ChapterSelect />

			{chapterFilter && (
				<>
					<hr css={baseStyles.hr} />

					<h2 css={styles.numQuestionsHeading}>{statusText}</h2>
					<div css={styles.buttonContainer}>
						<a
							css={styles.jumpToUnanswered}
							href={firstUnansweredQuestionAnchor}
							aria-disabled={!firstUnansweredQuestionAnchor}>
							Jump to Unanswered Question
						</a>
						<WebtextButton
							onClick={handleShuffle}
							css={styles.outlineButton}
							disabled={activeQuestionPools.length <= 1}>
							Shuffle Questions
						</WebtextButton>
					</div>

					<div css={styles.questionList}>
						{activeQuestionPools.map((mcqp, i) => (
							<MCQuestionPool
								key={mcqp.family_id}
								index={i}
								numQuestions={activeQuestionPools.length}
								questionPool={mcqp}
							/>
						))}
					</div>

					<div css={styles.pageBottom}>
						<hr css={baseStyles.hr} />

						<a css={styles.scrollToTop} href="#header" onClick={handleScrollToTop}>
							Scroll Back to Top
						</a>
					</div>
				</>
			)}

			<ModalDialog
				id="no-question-text-copying–warning"
				ref={modalRef}
				role="alertdialog"
				title="NOTICE"
				actions={
					<WebtextButton onClick={() => modalRef.current?.close()}>Okay, got it.</WebtextButton>
				}>
				<p>Copying is not enabled for question text.</p>

				<p>
					If you need help with the questions, get in touch with your instructor or contact Support.
				</p>
			</ModalDialog>
		</>
	);
};

export default MCQuestionPoolList;
