import { useEffect, useRef } from 'react';

import * as ActionCable from '@rails/actioncable';
import { useRollbar } from '@rollbar/react';
import { useShallow } from 'zustand/react/shallow';

import { useStudyStackStore } from '../store';

// don't use import.meta.env.PROD or it'll exclude staging
ActionCable.logger.enabled = import.meta.env.MODE !== 'production';

const ACTION_CABLE_URL = (jwt: string) =>
	`https://${import.meta.env.VITE_SOOMO_CORE_API_HOST}/cable?jwt=${jwt}`;

export default function useActionCable() {
	const rollbar = useRollbar();
	const consumer = useRef<ActionCable.Consumer | null>(null);
	const { jwt, onActionCableConnected, onJobComplete, setAppLoadingState } = useStudyStackStore(
		useShallow((state) => ({
			jwt: state.jwt,
			onActionCableConnected: state.onActionCableConnected,
			onJobComplete: state.onJobComplete,
			setAppLoadingState: state.setAppLoadingState
		}))
	);

	useEffect(() => {
		if (!jwt) {
			return;
		}

		consumer.current ??= ActionCable.createConsumer(ACTION_CABLE_URL(jwt));
		const sub = consumer.current!.subscriptions.create('PushNotificationsChannel', {
			connected: () => {
				onActionCableConnected();
			},
			received: (data: { type: string; job_id: string }) => {
				if (data.type === 'background_job_complete') {
					onJobComplete(data.job_id);
				}
			},
			// @ts-expect-error typings are missing the argument
			disconnected: (arg: { willAttemptReconnect: boolean }) => {
				rollbar.warn('ActionCable disconnected', arg);
				if (!arg?.willAttemptReconnect) {
					rollbar.error('ActionCable disconnected and will not reconnect');
					setAppLoadingState('error-unknown');
				}
			},
			rejected: () => {
				rollbar.error('ActionCable rejected');
				setAppLoadingState('error-unknown');
			}
		});

		return () => {
			sub.unsubscribe();
		};
	}, [jwt, onActionCableConnected, onJobComplete, rollbar, setAppLoadingState]);
}
