import React, { useCallback, useRef, useState } from 'react';

import { useShallow } from 'zustand/react/shallow';

import { WebtextButton } from '@soomo/lib/components';
import { useAriaLiveAnnouncer } from '@soomo/lib/components/AriaLiveAnnouncer';

import * as styles from './styles';
import ModalDialog, { type ModalHandle } from '../ModalDialog';
import { ALL_CHAPTERS, useStudyStackStore } from '../store';
import { selectStatusText } from '../store/selectors';

const ChapterSelect: React.FC = () => {
	const {
		chapterFilter,
		setChapterFilter,
		chapters,
		chapterNamesIncludeNumbering,
		clearResponses
	} = useStudyStackStore(
		useShallow((state) => ({
			chapters: state.chapters,
			chapterFilter: state.chapterFilter,
			setChapterFilter: state.setChapterFilter,
			chapterNamesIncludeNumbering: state.config.chapter_names_include_numbering,
			clearResponses: state.clearResponses
		}))
	);

	const [selectedChapter, setSelectedChapter] = useState<string | null>(null);
	const generateButtonWrapperRef = useRef<HTMLSpanElement>(null);
	const modalRef = useRef<ModalHandle>(null);
	const { makePoliteAnnouncement } = useAriaLiveAnnouncer();

	const handleChangeSelectedChapter = useCallback(
		(e: React.ChangeEvent<HTMLSelectElement>) => setSelectedChapter(e.target.value),
		[]
	);

	const handleModalCancelClick = useCallback(() => modalRef.current?.close(), []);

	const handleModalContinueClick = useCallback(() => {
		setChapterFilter(selectedChapter!);
		if (chapterFilter) {
			clearResponses();
		}

		modalRef.current?.close();
		generateButtonWrapperRef.current?.focus();

		// get new status text immediately, before next render
		const statusText = selectStatusText(useStudyStackStore.getState())!;
		// polite announcement + 500ms delay is to work around strangeness in Safari + VoiceOver
		// (normally we'd prefer to issue an assertive announcement immediately)
		setTimeout(() => makePoliteAnnouncement(statusText), 500);
	}, [chapterFilter, clearResponses, makePoliteAnnouncement, selectedChapter, setChapterFilter]);

	const handleGenerateClick = useCallback(() => {
		if (chapterFilter === selectedChapter) return;

		if (chapterFilter) {
			modalRef.current?.open();
		} else {
			handleModalContinueClick();
		}
	}, [chapterFilter, handleModalContinueClick, selectedChapter]);

	return (
		<section css={styles.controlsSection}>
			<div css={styles.controls}>
				<div css={styles.chapterFilterSelectWrapper}>
					<select
						css={[styles.chapterFilterSelect, !selectedChapter && styles.noChapterSelected]}
						onChange={handleChangeSelectedChapter}
						aria-label="Select a chapter">
						{!selectedChapter && <option>Select a chapter</option>}
						<option value={ALL_CHAPTERS}>All chapters</option>
						{chapters.map((ch) => {
							const disabled = ch.available_question_pools === 0;
							return (
								<option key={ch.family_id} value={ch.family_id} disabled={disabled}>
									{/* some chapters like Introduction have a blank `chapter_number`, " " */}
									{!chapterNamesIncludeNumbering &&
										ch.chapter_number.trim() &&
										`${ch.chapter_number}: `}
									“{ch.name}”{disabled && ' (not yet available)'}
								</option>
							);
						})}
					</select>
				</div>
				<span ref={generateButtonWrapperRef} tabIndex={-1}>
					<WebtextButton
						disabled={selectedChapter == null || selectedChapter === chapterFilter}
						onClick={handleGenerateClick}
						css={styles.generateButton}>
						Generate My Study Stack
					</WebtextButton>
				</span>
			</div>

			<ModalDialog
				id="generate-new-study-stack-confirmation"
				ref={modalRef}
				title="Update your study stack?"
				actions={
					<>
						<WebtextButton variant="underlined" onClick={handleModalCancelClick}>
							Cancel
						</WebtextButton>
						<WebtextButton onClick={handleModalContinueClick}>Continue</WebtextButton>
					</>
				}>
				<p>
					When you generate a different set of questions, any study questions you’ve answered so far
					will be reset. Don’t worry! This won’t affect your webtext grade.
				</p>
				<p>Would you like to continue?</p>
			</ModalDialog>
		</section>
	);
};

export default ChapterSelect;
