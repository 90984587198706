import React from 'react';
import ReactDOM from 'react-dom';

import { Provider as RollbarProvider, ErrorBoundary as RollbarErrorBoundary } from '@rollbar/react';

import themes from '@soomo/lib/styles/themes';
import FontProvider from '@soomo/lib/styles/themes/FontProvider';
import ThemeProvider from '@soomo/lib/styles/themes/ThemeProvider';

import App from './App';

import type { Configuration } from 'rollbar';

const usingRollbar = !import.meta.env.DEV && import.meta.env.VITE_ROLLBAR_TOKEN;

const rollbarConfig: Configuration = usingRollbar
	? {
			accessToken: import.meta.env.VITE_ROLLBAR_TOKEN,
			environment: import.meta.env.MODE,
			captureUncaught: true,
			captureUnhandledRejections: true
		}
	: {};

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={themes['universal_velvet']}>
			<FontProvider />
			<RollbarProvider config={rollbarConfig}>
				<RollbarErrorBoundary>
					<App />
				</RollbarErrorBoundary>
			</RollbarProvider>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById('root')!
);
