import React from 'react';
import { FiAlertTriangle } from 'react-icons/fi';

import { css } from '@emotion/core';

import { supportEmail, supportPhone } from '@soomo/lib/properties/support';

import * as baseStyles from './styles';

const ErrorUnknown: React.VFC = () => {
	return (
		<div css={baseStyles.errorPage}>
			<h1 css={styles.heading}>
				<FiAlertTriangle css={styles.icon} />
				An error has occurred.
			</h1>

			<p>
				In order to continue, please close this browser window or tab and then relaunch Study Stack
				from the Tools Menu of your webtext.
			</p>

			<p>
				If the error persists, use the Soomo Messenger button in the lower right corner of the
				screen to contact Soomo Support.
			</p>

			<p>
				You can also call us at {supportPhone} or email us at{' '}
				<a target="_blank" href={`mailto:${supportEmail}`} rel="noreferrer">
					{supportEmail}
				</a>
				.
			</p>
		</div>
	);
};

export default ErrorUnknown;

const styles = {
	heading: css`
		position: relative;
		display: flex;
		align-items: center;
	`,
	icon: css`
		position: absolute;
		color: #ed0404;
		left: -44px;
	`
};
