import React, { memo } from 'react';

import { last } from 'lodash-es';
import { useShallow } from 'zustand/react/shallow';

import StandaloneStudentView from '@soomo/lib/components/pageElements/MultipleChoiceQuestionPool/components/StandaloneStudentView';
import {
	getMCQuestionPoolPromptBodyId,
	getMCQuestionPoolId
} from '@soomo/lib/components/pageElements/MultipleChoiceQuestionPool/utils';
import type { MCQuestionPoolElement } from '@soomo/lib/types/WebtextManifest';

import * as styles from './styles';
import { useStudyStackStore } from '../store';

interface Props {
	index: number;
	numQuestions: number;
	questionPool: MCQuestionPoolElement;
}

const MCQuestionPool: React.FC<Props> = memo((props) => {
	const { index, numQuestions, questionPool } = props;
	const { appLoadingState, userId, saveQuestionPool, resetQuestionPool, response } =
		useStudyStackStore(
			useShallow((state) => ({
				appLoadingState: state.appLoadingState,
				userId: state.userId,
				saveQuestionPool: state.saveQuestionPool,
				resetQuestionPool: state.resetQuestionPool,
				response: last(state.responses[questionPool.family_id])!
			}))
		);

	if (appLoadingState !== 'ready') {
		return null;
	}

	const { resetCount, answer, seed } = response;

	const rootId = getMCQuestionPoolId(questionPool.family_id);
	const promptId = getMCQuestionPoolPromptBodyId(questionPool.family_id);

	return (
		<div css={styles.root} id={rootId}>
			<h5 css={styles.heading}>
				Question {index + 1} of {numQuestions}
			</h5>
			<div css={styles.questionWrapper} role="group" aria-labelledby={promptId}>
				<StandaloneStudentView
					showInstructorView={false}
					userId={userId}
					attemptsAllowed={-1}
					questionPool={questionPool}
					resetCount={resetCount}
					onSave={saveQuestionPool}
					onReset={resetQuestionPool}
					answer={answer}
					promptId={promptId}
					seed={seed}
					saveButtonLabel="Check Answer"
					resetButtonLabel={
						questionPool.questions.length > 1 ? 'Try a Similar Question' : 'Try Again'
					}
					isStudyStack
				/>
			</div>
		</div>
	);
});
MCQuestionPool.displayName = 'MCQuestionPool';

export default MCQuestionPool;
