import React from 'react';

import { supportEmail } from '@soomo/lib/properties/support';

import * as baseStyles from './styles';

const ErrorNoToken: React.VFC = () => {
	return (
		<div css={baseStyles.errorPage}>
			<h1>Page not found</h1>
			<p>
				Looking for Study Stack? Head back to your webtext, open the Tools Menu, and click the Study
				Stack link.
			</p>
			<p>
				If you need help,{' '}
				<a target="_blank" href={`mailto:${supportEmail}`} rel="noreferrer">
					contact us
				</a>
				.
			</p>
		</div>
	);
};

export default ErrorNoToken;
