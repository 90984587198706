import { css } from '@emotion/core';

import { breakpoints, getThemeItem, type Theme } from '@soomo/lib/styles/themes';

import { MINIMUM_SIDE_SPACING } from '../styles';

export const root = (theme: Theme) => {
	const {
		fonts: { app: appFont }
	} = theme;

	return css`
		display: flex;
		flex-direction: column;
		font-family: ${getThemeItem(appFont, theme)};
		border: 1px solid #979797;

		@media (max-width: ${breakpoints.small}) {
			margin-left: -${MINIMUM_SIDE_SPACING}px;
			margin-right: -${MINIMUM_SIDE_SPACING}px;
		}

		& + & {
			margin-top: 29px;

			@media (max-width: ${breakpoints.small}) {
				margin-top: 14px;
			}
		}
	`;
};

export const heading = css`
	padding: 16px 16px 14px 16px;
	color: #333;
	font-size: 14px;
	font-weight: 400;
	line-height: 1;
	letter-spacing: 1.24px;
	text-transform: uppercase;
	background: #f5f5f5;
	border-bottom: 1px solid #ccc;
`;

export const questionWrapper = css`
	padding: 34px 30px;

	.reminder-text {
		display: none;
	}

	.answer-status {
		display: none;
	}

	.rejoinder-container {
		padding: 0;
	}
`;
