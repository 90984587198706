import { css } from '@emotion/core';

import { BaseTextStyles } from '@soomo/lib/components/pageElements/Text/styles';
import { breakpoints, getThemeItem, type Theme } from '@soomo/lib/styles/themes';

const MAIN_MAX_WIDTH = 800;
export const MINIMUM_SIDE_SPACING = 24;
const SIDE_SPACING_BREAKPOINT = `${MAIN_MAX_WIDTH + 2 * MINIMUM_SIDE_SPACING}px`;

export const root = (theme: Theme) => {
	const {
		fonts: { webtext: webtextFont }
	} = theme;

	return css`
		font-family: ${getThemeItem(webtextFont, theme)};
	`;
};

export const main = css`
	max-width: ${MAIN_MAX_WIDTH}px;
	margin: 0 auto;
	padding: 51px 0 60px;

	@media (max-width: ${breakpoints.small}) {
		padding-top: 22px;
	}

	@media (max-width: ${SIDE_SPACING_BREAKPOINT}) {
		margin: 0 ${MINIMUM_SIDE_SPACING}px;
	}
`;

export const bodyText = (theme: Theme) => css`
	${BaseTextStyles(theme)}

	p {
		@media (max-width: ${breakpoints.small}) {
			margin-bottom: 16px;
		}
	}
`;

export const errorPage = (theme: Theme) => css`
	font-family: ${theme.fonts['helvetica-neue']};
	line-height: 1.5;

	h1 {
		font-size: 36px;
		font-family: ${theme.fonts['helvetica-neue-bold']};
		font-weight: 700;
		line-height: 1.2;

		@media (max-width: ${breakpoints.small}) {
			font-size: 30px;
			line-height: 1.33;
		}
	}

	p {
		font-size: 18px;
	}

	* + p {
		margin-top: 20px;
	}
`;

export const h1 = css`
	margin: 0 0 18px;
	font-family: 'Publico Headline Web', Publico;
	font-weight: 800;
	font-style: italic;
	font-size: 56px;
	line-height: 60px;

	@media (max-width: ${breakpoints.small}) {
		margin-bottom: 12px;
		font-size: 28px;
		line-height: 31px;
	}
`;

export const controlsHeader = (theme: Theme) => {
	const {
		fonts: { app: appFont }
	} = theme;

	return css`
		margin-top: 21px;
		font-family: ${getThemeItem(appFont, theme)};
		font-size: 24px;
		font-weight: 500;
		line-height: 1;

		@media (max-width: ${breakpoints.small}) {
			font-size: 18px;
			line-height: 30px;
		}
	`;
};

export const hr = css`
	margin: 20px 0;
	border: none;
	border-top: 3px solid #e6e6e6;
`;

export const loader = (theme: Theme) => {
	const {
		loader: {
			colors: { primary: primaryLoaderColor }
		},
		fonts: { app: appFont }
	} = theme;

	return css`
		display: flex;
		margin-top: 48px;
		flex-direction: column;
		align-items: center;
		row-gap: 3px;
		color: ${getThemeItem(primaryLoaderColor, theme)};
		font-family: ${getThemeItem(appFont, theme)};
		font-size: 18px;
	`;
};
